@import "./vendor/normalize";

// стили для модального окна
// @import "../../node_modules/graph-modal/dist/graph-modal.min";

// стили для табов
// @import "../../node_modules/graph-tabs/dist/graph-tabs.min";

// стили для библиотеки aos
// @import "../../node_modules/aos/dist/aos";

// стили для свайпера
// @import "../../node_modules/swiper/swiper-bundle.min";

// стили для скролла simplebar
// @import "../../node_modules/simplebar/dist/simplebar.min";

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1230px;
  }
}

.navbar-toggler {
  border: none;
}

.btn-danger {
  background-color: var(--orange);
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--violet-200);
  border-radius: 10px;
  padding: 3px !important;
}

.navbar-toggler {
  padding: 4px 5px 5px;

  &:focus {
    border: 1px solid var(--violet-200);
    box-shadow: none;
    border-radius: 12px;
  }
}

.header-top__icon {
  padding: 2px 4px 4px 4px;
  &:focus, &:active {
    border: 1px solid var(--violet-200);
    border-radius: 12px;
  }
}

.dropdown {
  &--no-arrow {
    .dropdown-toggle::after {
      content: none;
    }
  }
}

.form-control {
  padding: 14px 16px;
  font-size: 13px;
  color: var(--dark);
  border-color: var(--orange);

  &::placeholder {
    color: var(--dark);
  }
}
